<template>
  <div class="list-form-tbth-2024">
    <section class="p-3 p-md-4 p-xl-5">
      <div class="container">
        <div class="btn-group mb-3 button-margin">
          <button
            type="button"
            class="btn btn-lg btn-block btn-download"
            @click="onExportExcel"
          >
            Export Excel
          </button>
          <button
            type="button"
            class="btn btn-lg btn-block"
            @click="onGetListTBTH"
          >
            List TBTH
          </button>
          <button
            type="button"
            class="btn btn-lg btn-block"
            @click="onGetListLFV"
          >
            List LFV
          </button>
        </div>
        <h2>
          Danh sách khách mời đăng ký Voucher
          <input type="checkbox" v-model="get_all" @change="onGetAllChanged" />
        </h2>
        <h4>{{ site.name }}</h4>
        <table class="table table-hover table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>STT</th>
              <th v-if="get_all">Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Company</th>
              <th>Position</th>
              <th>Submitted</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in list_registered"
              :key="item.stt + index"
            >
              <td>{{ item.stt }}</td>
              <td v-if="get_all">
                <a :href="'./../grand-opening/' + item.code" target="_blank">{{
                  item.code
                }}</a>
              </td>
              <td>{{ item.fullname }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.company }}</td>
              <td>{{ item.position }}</td>
              <td>{{ item.submitted_date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  name: "ListRegisteredTBTH",
  data() {
    return {
      site: {
        id: 1,
        name: "",
        url: "",
      },
      get_all: false,
      list_registered: [],
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    isNull(data) {
      if (
        data == undefined ||
        data == null ||
        data.length == 0 ||
        data == 0 ||
        data == ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    formatSqlDate(date, fromFormat, toFormat) {
      if (!this.isNull(date)) {
        if (this.isNull(fromFormat)) {
          return moment(date).format(toFormat);
        }
        if (this.isNull(toFormat)) {
          return moment(date, fromFormat);
        }
        return moment(date, fromFormat).format(toFormat);
      }
      return "";
    },
    onGetListTBTH() {
      this.site.id = 1;
      this.getListRegistered();
    },
    onGetListLFV() {
      this.site.id = 2;
      this.getListRegistered();
    },
    onGetAllChanged() {
      if (this.get_all == true) {
        let pass = prompt("Nhập mật khẩu để mở tính năng");
        if (pass === "ict@58ndc") {
          this.getListRegistered();
        } else {
          if (!this.isNull(pass)) {
            alert("Sai mật khẩu");
          }
          this.get_all = false;
        }
      } else {
        this.getListRegistered();
      }
    },
    getListRegistered() {
      let data = {
        id: this.site.id,
        params: {
          all: this.get_all,
        },
      };
      this.GET_SUBMITTED_FORM_LIST(data)
        .then(
          function (res) {
            let data = res.data;
            this.site.id = data.site.id;
            this.site.name = data.site.name;
            this.site.url = data.site.url;
            this.list_registered = [];
            for (
              let i = 0, data_length = data.forms_data.length;
              i < data_length;
              i++
            ) {
              let obj = {
                stt: i + 1,
                code: data.forms_data[i].code,
                email: data.forms_data[i].email,
                fullname: data.forms_data[i].fullname,
                phone: data.forms_data[i].phone,
                company: data.forms_data[i].company,
                position: data.forms_data[i].position,
                submitted_date: this.formatSqlDate(
                  data.forms_data[i].submitted_date,
                  null,
                  "DD/MM/YYYY HH:mm"
                ),
              };
              this.list_registered.push(obj);
            }
          }.bind(this)
        )
        .catch(function () {
          this.list_registered = [];
          alert("Somethong went wrong when retrieving ist. Please try again");
        });
    },
    renameKey: function (object, key, newKey) {
      const targetKey = object[key];
      delete object[key];
      object[newKey] = targetKey;
      return object;
    },
    onExportExcel() {
      let data = {
        params: {
          all: this.get_all,
        },
      };
      this.GET_DOWNLOAD_FORM_LIST(data)
        .then(
          function (res) {
            let data = res.data;
            let registered_list = [];
            for (
              let i = 0, data_length = data.forms_data.length;
              i < data_length;
              i++
            ) {
              let obj = {
                stt: i + 1,
                site: data.forms_data[i].site,
                code: data.forms_data[i].code,
                fullname: data.forms_data[i].fullname,
                email: data.forms_data[i].email,
                phone: data.forms_data[i].phone,
                company: data.forms_data[i].company,
                position: data.forms_data[i].position,
                submitted_date: this.formatSqlDate(
                  data.forms_data[i].submitted_date,
                  null,
                  "DD/MM/YYYY HH:mm"
                ),
              };
              registered_list.push(obj);
            }
            this.doExportToExcelFile(registered_list);
          }.bind(this)
        )
        .catch(function () {
          this.list_registered = [];
          alert("Somethong went wrong when retrieving ist. Please try again");
        });
    },
    doExportToExcelFile(dataList) {
      let registered_list = dataList;

      for (let i = 0, size = registered_list.length; i < size; i++) {
        for (var property in registered_list[i]) {
          if (property === "stt") {
            this.renameKey(registered_list[i], property, "STT");
          } else if (property === "site") {
            this.renameKey(registered_list[i], property, "Property");
          } else if (property === "code") {
            this.renameKey(registered_list[i], property, "Code");
          } else if (property === "email") {
            this.renameKey(registered_list[i], property, "E-Mail");
          } else if (property === "fullname") {
            this.renameKey(registered_list[i], property, "Customer Name");
          } else if (property === "phone") {
            this.renameKey(registered_list[i], property, "Phone Number");
          } else if (property === "company") {
            this.renameKey(registered_list[i], property, "Company");
          } else if (property === "position") {
            this.renameKey(registered_list[i], property, "Position");
          } else if (property === "submitted_date") {
            this.renameKey(registered_list[i], property, "Submitted Date");
          }
        }
      }

      let list_export = XLSX.utils.json_to_sheet(registered_list);

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, list_export, "Customer List"); // 3rd param is name of Worksheet

      // export Excel file
      // XLSX.writeFile(wb, `${this.site.name}` + "_customer_list.xlsx"); // name of the file is 'book.xlsx'
      XLSX.writeFile(wb, "registered_customer_list.xlsx"); // name of the file is 'book.xlsx'
    },
    ...mapActions(["GET_SUBMITTED_FORM_LIST", "GET_DOWNLOAD_FORM_LIST"]),
  },
  components: {},
  mounted() {
    this.getListRegistered();
  },
};
</script>

<style lang="scss" scoped>
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css);

/* Hiệu ứng khi di chuột qua hàng */
.table-hover tbody tr:hover {
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}
/* Bảng có đường viền và font chữ */
.table-bordered {
  border: 2px solid #dee2e6;
  font-size: 16px;
}
/* Căn chỉnh các nút button và tạo khoảng cách giữa chúng */
.btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.btn-group .btn {
  flex-grow: 1;
  margin: 0 5px;
}
/* Định dạng cho các button */
.btn-group .btn {
  background-color: midnightblue; /* Màu xám */
  color: #fff; /* Màu chữ trắng */
}
.btn-download {
  background-color: darkred !important;
}
/* Canh giữa dòng "Thông tin người dùng" */
.center-text {
  text-align: center;
}
/* Khoảng cách giữa dòng "Thông tin người dùng" và các button */
.button-margin {
  margin-t: 50px;
}
/* Khoảng cách giữa dòng "Thông tin người dùng" và đỉnh trang */
.header-margin {
  margin-top: 50px;
}

.center-text {
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
