import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import RegiteredTBTHList from "../views/forms/RegiteredTBTHList.vue";
import FormTBTH2024 from "../views/forms/FormTBTH2024.vue";
import FormTBTHSuccess from "../views/forms/FormTBTHSuccess.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/grand-opening/:code",
    name: "FormTBTH",
    component: FormTBTH2024,
  },
  {
    path: "/tbth-successed/:site",
    name: "FormTBTHSuccess",
    component: FormTBTHSuccess,
  },
  {
    path: "/tbth-registered",
    name: "RegiteredTBTHList",
    component: RegiteredTBTHList,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
