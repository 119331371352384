<template>
  <div :class="site_id == 2 ? 'form-lfv-2024' : 'form-tbth-2024'">
    <div class="container">
      <div class="message-container">
        <div class="image-container">
          <img
            v-if="site_id == 2"
            src="../../assets/logo-lfv.jpg"
            alt="Hình ảnh"
            class="img-fluid image-lfv"
          />
          <img
            v-else
            src="../../assets/logo-tbth.jpg"
            alt="Hình ảnh"
            class="img-fluid image-tbth"
          />
        </div>
        <p class="message">
          Cảm ơn quý khách đã đăng ký nhận quà tặng voucher thành công!<br />Voucher
          điện tử sẽ được gởi đến email của Quý khách trong thời gian sớm nhất!
        </p>
        <button type="button" class="btn btn-custom" @click="onRedirectSite">Đóng</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "fromTBTHRegisterSuccessed",
  data() {
    return {
      site_id: 0,
      site_name: "",
      site_url: "",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    onRedirectSite() {
      let url = this.site_url;
      if (
        url == undefined ||
        url == null ||
        url.length == 0 ||
        url == 0 ||
        url == ""
      ) {
        url = "https://tuibluetuyhoa.com/"
      }
      window.open(url, "_self");
    },
    getSiteInfo() {
      let data = {
        id: this.site_id,
      };
      this.GET_SITE_INFO(data)
        .then(
          function (res) {
            let data = res.data;
            this.site_id = data.site.id;
            this.site_name = data.site.name;
            this.site_url = data.site.url;
          }.bind(this)
        )
        .catch(function () {
          // alert("Somethong went wrong when retrieving ist. Please try again");
        });
    },
    ...mapActions(["GET_SITE_INFO"]),
  },
  components: {},
  mounted() {
    this.site_id = this.$route.params.site;
    this.getSiteInfo();
  },
};
</script>

<style lang="scss" scoped>
.form-tbth-2024 {
  background-image: url("../../assets/backgroud-tbth.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form-lfv-2024 {
  background-image: url("../../assets/backgroud-lfv.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.message-container {
  background-color: rgba(255, 255, 255, 0.8); /* Thêm độ trong suốt cho nền */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.image-container {
  margin-bottom: 30px;
}

.image-tbth {
  width: 200px;
  height: auto;
  border-radius: 10px; /* Đảm bảo các góc trở nên vuông hơn */
}

.image-lfv {
  width: 600px;
  height: auto;
  border-radius: 10px; /* Đảm bảo các góc trở nên vuông hơn */
}

.message {
  font-size: 18px;
  color: black;
  margin-bottom: 20px;
}

.btn-custom {
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 18px;
}
</style>
