import commonRequest from "./../../api/index";

const state = {
  submitted_form_data: [],
  download_form_data: [],
};

const getters = {
  LIST_SUBMITTED_FORM_DATA: (state) => {
    return state.submitted_form_data;
  },
  GET_DOWNLOAD_FORM_LIST_DATA: (state) => {
    return state.download_form_data;
  },
};

const actions = {
  CHECK_VALID_CODE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/validate_code/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
  POST_SUBMIT_FORM: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `api/${payload.id}/submit_form/`,
        payload,
        function (res) {
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
  GET_SUBMITTED_FORM_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/${payload.id}/get_list_forms/`,
        payload,
        function (res) {
          context.commit("GET_SUBMITTED_FORM_LIST_HANDLER", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
  GET_DOWNLOAD_FORM_LIST: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/down_list_forms/`,
        payload,
        function (res) {
          context.commit("GET_DOWNLOAD_FORM_LIST_HANDLER", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  GET_SUBMITTED_FORM_LIST_HANDLER: (state, payload) => {
    state.submitted_form_data = payload;
  },
  GET_DOWNLOAD_FORM_LIST_HANDLER: (state, payload) => {
    state.download_form_data = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
