import { createStore } from "vuex";
import form from "./form";
import site from "./site";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    form,
    site,
  },
});
