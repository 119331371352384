import commonRequest from "../../api/index";

const state = {};

const getters = {};

const actions = {
  GET_SITE_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `api/${payload.id}/site_info/`,
        payload,
        function (res) {
          context.commit("GET_SUBMITTED_FORM_LIST_HANDLER", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
};

const mutations = {
};

export default {
  state,
  getters,
  actions,
  mutations,
};
