import axios from "axios";

const getRequest = function (url, data, resolve, reject, timeout = 60000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      "Content-Type": "application/json",
    },
  };
  // const token = isCookieEnabled() ? getCookie('token') : ''
  // if (token) {
  //   config.headers['Authorization'] = `JWT ${token}`
  // }
  const instance = axios.create(config);
  instance
    .get(url, data)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
};

const postRequest = function (url, data, resolve, reject, timeout = 60000) {
  const config = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: timeout,
    headers: {
      "Content-Type": "application/json",
    },
  };
  // const token = isCookieEnabled() ? getCookie("token") : "";
  // if (token) {
  //   config.headers["Authorization"] = `JWT ${token}`;
  // }
  const instance = axios.create(config);
  instance
    .post(url, data)
    .then((res) => {
      resolve(res);
    })
    .catch((error) => {
      reject(error);
    });
};

export default {
  getRequest,
  postRequest,
  // deleteRequest,
  // putRequest
};
