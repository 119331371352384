<template>
  <div class="form-tbth-2024">
    <section
      v-if="codeStatus == CODE_SUBMIT_STATUS.UNCONFIRMED"
      class="p-3 p-md-4 p-xl-5"
    >
      <div class="container">
        <div class="card border-light-subtle shadow-sm">
          <div class="row g-0">
            <div class="col-12 col-md-6">
              <img
                v-if="site.id == 2"
                class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                loading="lazy"
                width="100px"
                height="100px"
                src="../../assets/brand-lfv.jpg"
                alt="Site Logo"
              />
              <img
                v-else
                class="img-fluid rounded-start w-100 h-100 object-fit-cover"
                loading="lazy"
                width="100px"
                height="100px"
                src="../../assets/brand-tbth.jpg"
                alt="Site Logo"
              />
            </div>
            <div class="col-12 col-md-6">
              <div class="card-body p-3 p-md-4 p-xl-5">
                <div class="row">
                  <div class="col-12">
                    <div class="mb-5">
                      <h2 class="h3">PHIẾU ĐĂNG KÝ NHẬN QUÀ TẶNG VOUCHER</h2>
                      <h3 class="fs-6 fw-normal text-secondary m-0">
                        Vui lòng cung cấp cho chúng tôi những thông tin sau:
                      </h3>
                    </div>
                  </div>
                </div>
                <form @submit.prevent="onSubmitForm">
                  <div class="row gy-3 gy-md-4 overflow-hidden">
                    <div class="col-12">
                      <label for="fullname" class="form-label"
                        >Họ tên<span class="text-danger">*</span></label
                      >
                      <input
                        v-model="fullname"
                        type="text"
                        class="form-control"
                        name="fullname"
                        id="fullname"
                        placeholder="Họ Tên"
                        oninvalid="setCustomValidity('Họ tên không được để trống')"
                        oninput="setCustomValidity('')"
                        title="Vui lòng nhập họ tên"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="email" class="form-label"
                        >Email <span class="text-danger">*</span></label
                      >
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="name@example.com"
                        oninvalid="setCustomValidity('Email không được để trống')"
                        oninput="setCustomValidity('')"
                        title="Vui lòng nhập email"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="phone" class="form-label"
                        >Số điện thoại <span class="text-danger">*</span></label
                      >
                      <input
                        v-model="phone"
                        type="tel"
                        class="form-control"
                        name="phone"
                        placeholder="Số điện thoại"
                        oninvalid="setCustomValidity('Số điện thoại không được để trống')"
                        oninput="setCustomValidity('')"
                        title="Vui lòng nhập số điện thoại"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="company" class="form-label"
                        >Đơn vị công tác
                        <span class="text-danger">*</span></label
                      >
                      <input
                        v-model="company"
                        type="text"
                        class="form-control"
                        name="company"
                        placeholder="Công ty ABC"
                        oninvalid="setCustomValidity('Vui lòng nhập tên công ty')"
                        oninput="setCustomValidity('')"
                        title="Vui lòng nhập đơn vị công tác"
                        required
                      />
                    </div>
                    <div class="col-12">
                      <label for="position" class="form-label">Chức vụ</label>
                      <input
                        v-model="position"
                        type="text"
                        class="form-control"
                        name="position"
                        oninvalid="setCustomValidity('Vui lòng nhập chức vụ của bạn')"
                        oninput="setCustomValidity('')"
                        title="Vui lòng nhập chức vụ của bạn trong công ty"
                        placeholder="Trưởng phòng"
                      />
                    </div>
                    <div class="col-12">
                      <div class="d-grid">
                        <button
                          class="btn bsb-btn-xl btn-primary"
                          type="submit"
                        >
                          Đăng ký
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="codeStatus == CODE_SUBMIT_STATUS.HAS_SUBMITED"
      class="p-3 p-md-4 p-xl-5"
    >
      <h2 style="color: blue">Bạn đã gởi thông tin đăng ký thành công</h2>
    </section>
    <section v-else class="p-3 p-md-4 p-xl-5">
      <h2 style="color: red">Thông tin không chính xác, vui lòng thử lại</h2>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

const CODE_SUBMIT_STATUS = {
  UNKNOWN: 0,
  UNCONFIRMED: 1,
  HAS_SUBMITED: 2,
  INVALID_CODE: 3,
};

export default {
  name: "fromTBTHGrandOpening",
  data() {
    return {
      CODE_SUBMIT_STATUS: CODE_SUBMIT_STATUS,
      codeStatus: CODE_SUBMIT_STATUS.UNKNOWN,
      site: {
        id: 0,
        name: "",
        url: "",
      },
      fullname: "",
      email: "",
      phone: "",
      company: "",
      position: "",
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    validateCode() {
      let filter = {
        params: {
          code: this.$route.params.code,
        },
      };
      this.CHECK_VALID_CODE(filter)
        .then(
          function (res) {
            let data = res.data;
            this.site.id = data.site_info.id;
            this.site.name = data.site_info.name;
            this.site.url = data.site_info.url;
            this.codeStatus = this.CODE_SUBMIT_STATUS.UNCONFIRMED;
          }.bind(this)
        )
        .catch(
          function (error) {
            // let errorText = error.response.data.message;
            if (error.response.status === 404) {
              this.codeStatus = this.CODE_SUBMIT_STATUS.INVALID_CODE;
            } else if (error.response.status === 406) {
              this.codeStatus = this.CODE_SUBMIT_STATUS.HAS_SUBMITED;
              this.site.id = error.response.data.site_info.id;
              this.site.name = error.response.data.site_info.name;
              this.site.url = error.response.data.site_info.url;
              this.goToSUccessedPage();
            } else {
              this.codeStatus = this.CODE_SUBMIT_STATUS.UNKNOWN;
            }
          }.bind(this)
        );
    },
    onSubmitForm() {
      let data = {
        id: this.site.id,
        code: this.$route.params.code,
        fullname: this.fullname,
        email: this.email,
        phone: this.phone,
        company: this.company,
        position: this.position,
      };
      this.POST_SUBMIT_FORM(data)
        .then(
          function () {
            this.codeStatus = this.CODE_SUBMIT_STATUS.HAS_SUBMITED;
            this.goToSUccessedPage();
          }.bind(this)
        )
        .catch(function () {
          alert(
            "Có lỗi xảy ra khi đăng ký thông tin. Vui lòng cờ giây lát và thử lại!!!"
          );
        });
    },
    goToSUccessedPage() {
      this.$router.push({
        name: "FormTBTHSuccess",
        params: {
          site: this.site.id,
        },
      });
    },
    ...mapActions(["CHECK_VALID_CODE", "POST_SUBMIT_FORM"]),
  },
  components: {},
  mounted() {
    this.validateCode();
  },
};
</script>

<style lang="scss" scoped>
@import url(https://unpkg.com/bootstrap@5.3.2/dist/css/bootstrap.min.css);
.form-label {
  text-align: left;
  display: block;
}
</style>
